.container {
  display: flex;
  justify-content: center;
  height: 100vh;
}

.containerloader {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}
